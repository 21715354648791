
import { Headers, Http, Response } from '@angular/http';

export abstract class SmartTableData {
  //protected  http: Http;
    //protected headers:Headers;
 /*   
  constructor(
     http: Http,
    headers:Headers) {
  this.http=http;		
  this.headers=headers;
  }*/
  /*constructor(
    protected headers2: Headers){}
*/
  killCache() : String {
    return new Date().getTime() + '';
}
  
  abstract getData(): any[];
  //abstract get(table:string, id:number,x:string): Promise<any>;
  
}
