//import { Component, OnDestroy, Input } from '@angular/core';
//import { BackendService } from '../../../@core/backend/backend.service';
import { Component, OnDestroy, Input } from '@angular/core';
import { NbLayoutDirectionService, NbLayoutDirection } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';

import { BackendService } from '../../../@core/backend/backend.service';
import { LoginService } from '../../../@core/login/login.service';
import { ToasterConfig } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { NbGlobalLogicalPosition, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { ExtraOptions, RouterModule, Routes,Router } from '@angular/router';


//<a href='#/pages/forms/branch'>{{user?.branch_name}}</a>
    
@Component({
  selector: 'ngx-branch-text',
  template: `
    <ngx-select-dropdown tabindex="0" style="with:100px" (change)="onChange()" [(ngModel)]="selectedBranch"    [config]="configBranchs" [disabled]="configBranchs.disabled" [options]="branchs"></ngx-select-dropdown>
  `,
})
export class BranchTextComponent implements OnDestroy {
  
  branch_name:string;
  selectedBranch:any;
  alive = true;
  
  branchs:any;
  constructor(    private backendService: BackendService ,
    private toastrService: NbToastrService,
    private router:Router,
  
    private loginService: LoginService //,
    
    ) {
      this.branch_name='';
      this.getBranchs();
      this.backendService.get('branch/session/')
                 .subscribe((data:any) => {
           console.log(data);    
           this.selectedBranch=data;  
           //this.selectedBranch={name:'Sede Central'}
         });
      
      
    }

   
    configBranchs = {
      disabled:false,
      displayKey:"name", //if objects array passed which key to be displayed defaults to description
      search:true, //true/false for the search functionlity defaults to false,
      height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder:' Sedes  ', // text to be displayed when no item is selected defaults to Select,
      customComparator: ()=>{} ,// a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      //limitTo: options.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      limitTo:10,
      moreText: 'mas' ,// text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No resultados!', // text to be displayed when no items are found while searching
      searchPlaceholder:'Buscar..', // label thats displayed in search input,
      searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
      
    getBranchs() {
      this.backendService.get('branchs').subscribe((data: any) => {
        this.branchs=data;
        //this.coberturas=['111', '222','333','4444'];
        console.log(this.branchs);
      });
    } 
    
    private showToast(state: string, title: string, body: string) {
      let type:NbToastStatus;
      if (state=='ERROR'){
        type=NbToastStatus.DANGER;
      }
      else if (state=='WARNING'){
        type=NbToastStatus.WARNING;
      }
      else if (state=='OK'){
        type=NbToastStatus.SUCCESS;
      }
      else type=NbToastStatus.INFO;
      const config = {
        status: type,
        destroyByClick: true,
        duration: 6000,
        hasIcon: true,
        position:  NbGlobalPhysicalPosition.TOP_RIGHT,
        preventDuplicates: false,
      };
      const titleContent = title ? state+`. ${title}` : '';
  
      this.toastrService.show(
        body,
        `${titleContent}`,
        config);
    }
 onChange(): void{  
  let reg={branch_name: this.selectedBranch.name};
  this.branch_name=this.selectedBranch.name;    
  this.backendService.post2('branch/session',reg).subscribe((data: any) => {
    this.loginService.setUserInfo(data);
    this.loginService.setLoginStatus(true);
    this.showToast('OK', 'Selección Aceptada', 'Se selecciono  '+this.branch_name+', acceda al sistema');
    /*this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['pages','tables','pacientes-potenciales-table']);
      //this.router.navigate(['pages','forms','branch']);
  }); */
  }); 
 }
  
  
  /*
  branchSelectedId:number;
  branchSelectedName:string;
  user: any;

  @Input() vertical: boolean = false;

  constructor(
    private backendService: BackendService,
                
  ) {
    this.branchSelectedName='Miami';
    this.branchSelectedId=5;
    
  }
  ngOnInit() {
    this.backendService.get('/user/session/')
    .subscribe((user: any) => this.user = user);
   
    //this.backendService.get('user_session')
    //.subscribe((user: any) => this.user = user);
    //this.userService.getUsers()
    //  .subscribe((users: any) => this.user = users.nick);
  }
*/
ngOnDestroy() {
  this.alive = false;
}  
}
