import { Injectable, NgZone } from '@angular/core';
import { Headers,  Response } from '@angular/http';
import { HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { BackendService } from '../backend/backend.service';

//import { AppSettingsService } from '../backend/app-settings.service';




import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/filter';
import { Profile } from '../../../../node_modules/@types/selenium-webdriver/firefox';

declare var gapi: any;
@Injectable()
export class ConfigService {

	private userInfo;
    private loginObservable = new BehaviorSubject<boolean>(false);
	private loggedIn:boolean = false;
    public auth2: any;
    config: any;
    private leido:boolean;
    constructor(
        private ngZone: NgZone,
        private http: HttpClient,
		private backendService:BackendService,
		private router:Router,
		//private appSettingsService: AppSettingsService
		) {
		console.log('constr CONFIG');
		this.leido=false;
		this.backendService.get('configuration/')		
			.subscribe( (data: any) => {
				this.config = data;	
				this.leido=true;
				console.log(this.config);
			});
		//this.setDelay(2);	 
		console.log('--> configService constructor');
		/*this.ngZone.run(() => {
		this.loginObservable.subscribe((loggedIn) => {
			console.log('--> configService loginObservable', loggedIn);
		});
	   });*/
	}
	private  setDelay(i) {
		setTimeout(function(){
			console.log('setDelay');

			console.log(this.leido);
			console.log(i);
		}, 1000 * i);
	}
	public refresh(): Observable<any>{
		this.leido=false;
		return this.backendService.get('configuration/');		
			
	}	
	public setData(data:any){
		this.config = data;	
		this.leido=true;

	}	
	public get(name:string) {
		try{
			console.log('Configgetthis');
			if(this.leido) console.log('ConfiLEIDO');
			else console.log('ConfiNOOOOLEIDO');
			console.log(this);
			return this.config[name];
			let i: number = 2;
		while (i < 1000) {
			if (this.leido)
			return this.config[name];
			else this.setDelay(1);
			i++;
		}
		console.log('No se pudo leer la configuracion :'+name)

		}
		catch(e)
		{
			console.log('No se pudo encontrar Config:'+name)
		}
	}
	
	

	
	
	
    
    
    
}
