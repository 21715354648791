import { Injectable } from '@angular/core';
//import { Headers, Http, Response } from '@angular/http';
import { HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';

import { AppSettingsService } from './app-settings.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map'; 

@Injectable()
export class BackendService {
	//url:string = 'https://200.69.211.17/api/test/';
	//url:string = 'https://200.69.211.17/api/desa/';
	url:string;// = 'http://localhost:100/api/desa/';
	constructor(
		private http: HttpClient,
		private appSettingsService: AppSettingsService) {
	    this.url=this.appSettingsService.API_ENDPOINT_V1; 		//
	}
  
	killCache() : String {
	    return new Date().getTime() + '';
	}
	getAmbiente(){
		return this.appSettingsService.AMBIENTE;
	}
	getbyid(message:string,id:number): Promise<any> {
		const params = new HttpParams().set('page', '1');
		this.http.get('/api/users', { params });
		const token='YWxhZGRpbjpvcGVuc2VzYW1l';
		const headers = new HttpHeaders().set('Authorization', `Basic ${token}`);
		let killCache = this.killCache();
		let url = `https://kehillanew.onkelos.com.ar/api/v1/${message}?killCache=${killCache}`;
		
		return this.http.get(url, { headers })
		.toPromise()
		.then( r => {
			console.log('backend.get got r=', r);
			return r;
		})
		.catch((error: any) => {
			console.error('An friendly error occurred', error);
			return error;
		});
	}

	get(message:string): Observable<any> {
		const params = new HttpParams().set('page', '1');
		this.http.get('/api/users', { params });
		const token='YWxhZGRpbjpvcGVuc2VzYW1l';
		const headers1 = new HttpHeaders().set('Authorization', `Basic ${token}`);


		const headers= new HttpHeaders({ 
				'Access-Control-Allow-Origin':'*',
				'Authorization':'authkey',
				'userid':'1'
			})
		
		let killCache = this.killCache();

		//let url = `https://kehilla.onkelos.com.ar/api/v1/${message}?usuario=1&account=001&killCache=${killCache}`;
		//let url = `/api/v1/${message}?usuario=1&account=001&killCache=${killCache}`;
//		let url = `http://kehillanew.onkelos.com.ar/api/v1/${message}?usuario=1&account=002&killCache=${killCache}`;
		let url ='';
		console.log(message);
        if (message.indexOf('?')>0)
        	url = `${this.url}${message}&killCache=${killCache}`;
		else url = `${this.url}${message}?killCache=${killCache}`;	
		//let url = `https://kehillanew.onkelos.com.ar/api/v1/${message}?killCache=${killCache}`;
		return this.http.get(url, { headers });
/*




		//let headers = new Headers();
		//let userInfo = this.loginService.getUserInfo();
		//if (userInfo) {
			//headers.append('email', userInfo.email);
			//headers.append('token', userInfo.token);
			headers.append('email', 'pepe@kehilla');
			headers.append('token', 'AAA33BBB555');
//			let killCache = this.killCache();
			this.http.get(url)
			.subscribe((data: any) => {
			  // Data extraction from the HTTP response is already done
			  // Display the result
			  console.log('TJ user data', data);
			});

			//let url = `${this.appSettingsService.API_ENDPOINT_V1}/${table}/${id}?killCache=${killCache}`;
//			let url = `https://kehilla.onkelos.com.ar/api/v1/${message}?usuario=1&account=001&killCache=${killCache}`;
			return this.http
			  .get(url, { headers: headers })
			  .map((r: Response) => {
					return r.json() as any
			  })
			  .toPromise()
			  .then( r => {
				  console.log('backend.get got r=', r);
				  return r;
			  })
			  .catch((error: any) => {
				  console.error('An friendly error occurred', error);
				  return error;
			  });
		//}
	*/	
	}

	getId(message:string,id:number): Observable<any> {
		const params = new HttpParams().set('page', '1');
		this.http.get('/api/users', { params });
		const token='YWxhZGRpbjpvcGVuc2VzYW1l';
		const headers = new HttpHeaders().set('Authorization', `Basic ${token}`);
		let killCache = this.killCache();
		//let url = `https://kehilla.onkelos.com.ar/api/v1/${message}?usuario=1&account=001&killCache=${killCache}`;
		//let url = `/api/v1/${message}?usuario=1&account=001&killCache=${killCache}`;
//		let url = `http://kehillanew.onkelos.com.ar/api/v1/${message}?usuario=1&account=002&killCache=${killCache}`;
		let url = `https://kehillanew.onkelos.com.ar/api/v1/${message}?killCache=${killCache}`;
		//let url = `https://kehilla.onkelos.com.ar/api/v1/${message}?usuario=1&account=001&killCache=${killCache}`;
		
		return this.http.get(url, { headers });
/*




		//let headers = new Headers();
		//let userInfo = this.loginService.getUserInfo();
		//if (userInfo) {
			//headers.append('email', userInfo.email);
			//headers.append('token', userInfo.token);
			headers.append('email', 'pepe@kehilla');
			headers.append('token', 'AAA33BBB555');
//			let killCache = this.killCache();
			this.http.get(url)
			.subscribe((data: any) => {
			  // Data extraction from the HTTP response is already done
			  // Display the result
			  console.log('TJ user data', data);
			});

			//let url = `${this.appSettingsService.API_ENDPOINT_V1}/${table}/${id}?killCache=${killCache}`;
//			let url = `https://kehilla.onkelos.com.ar/api/v1/${message}?usuario=1&account=001&killCache=${killCache}`;
			return this.http
			  .get(url, { headers: headers })
			  .map((r: Response) => {
					return r.json() as any
			  })
			  .toPromise()
			  .then( r => {
				  console.log('backend.get got r=', r);
				  return r;
			  })
			  .catch((error: any) => {
				  console.error('An friendly error occurred', error);
				  return error;
			  });
		//}
	*/	
	}
	post(table:string,item:any)
	{
		let url_post = `${this.url}${table}`;

		this.http.post(url_post,item)
		.subscribe(
		data  => {
		console.log("POST Request is successful ", data);
		},
		error  => {
		
		console.log("Error", error);
		
		});

	}
	delete(table:string, id:number){
		let killCache = this.killCache();
		let url_delete = `${this.url}${table}/${id}?killCache=${killCache}`;

		return this.http.delete(url_delete);
		
	}
	post2(table:string, item: any){
		let killCache = this.killCache();
		let url_post = `${this.url}${table}?killCache=${killCache}`;
				
			return this.http
			  .post(url_post, JSON.stringify(item), );
	}
	put(table:string, id:number, item: any) {
		let killCache = this.killCache();
		let url =  `${this.url}${table}?killCache=${killCache}`;
		return this.http
			.put(url, JSON.stringify(item),);
	}


	getFromDownload(path:string){
		let killCache = this.killCache();
		//let url = `${this.appSettingsService.API_ENDPOINT}/v1/${path}?killCache=${killCache}`;
		
				let url = `${this.url}${path}?killCache=${killCache}`;
				//let url = `https://kehillanew.onkelos.com.ar/api/v1/${path}?killCache=${killCache}`;
		
				window.open(url, "_blank");

	}	
	private handleError(error: any): Promise<any> {
		console.error('An error occurred', error);
		return Promise.reject(error.message || error);
	}
}
