import { Injectable, NgZone } from '@angular/core';

@Injectable()
export class AppSettingsService {

    //public API_ENDPOINT = '/api/index.php';
    //public API_ENDPOINT_V1 = '/api/v1';
    public API_ENDPOINT_V1:string;
    public AMBIENTE:string;
    public MODULE:string='global';
    public API_GET_IMAGE = '/api/get_image.php';
    public API_UPLOAD_PICTURE = '/api/upload_picture.php';
    public USE_IN_MEMORY_WEB_API = false;
   
    constructor() {
		let server='';
		let installedEnv = this.getInstalledEnv();
		 if (installedEnv === 'FLEXER_DESA') {
			this.AMBIENTE='desa';
      	}  
		else if (installedEnv === 'FLEXER_TEST') {
			this.AMBIENTE='test';
		}  
		else if (installedEnv === 'FLEXER_PROD') {
			//this.API_ENDPOINT = '/api/index.php';
			this.AMBIENTE='prod';
		}  
        else if (installedEnv === 'LOCAL4200'||installedEnv === 'LOCAL100') {
		   this.AMBIENTE='desa';
		   server='http://backend.fundacionflexer.test';

		}
		this.API_ENDPOINT_V1 = server+'/api/'+this.AMBIENTE+'/'+this.MODULE+'/';
		this.API_GET_IMAGE = '/api/get_image.php';
		this.API_UPLOAD_PICTURE = '/api/upload_picture.php';

    }
	
	public getInstalledEnv() {
		if (window.location.href.match('^http:\/\/localhost:4200')) {
			return 'LOCAL4200';
		}
		if (window.location.href.match('^http:\/\/localhost:100')) {
			return 'LOCAL4200';
		}
		if (window.location.href.match('/desa/'+this.MODULE)) {
			return 'FLEXER_DESA';
		}
		if (window.location.href.match('/test/'+this.MODULE)) {
			return 'FLEXER_TEST';
		}
		if (window.location.href.match('/prod/'+this.MODULE)) {
			return 'FLEXER_PROD';
		}
		else {
			return 'LOCAL4200';
		}
		/*if (window.location.href.match('^https:\/\/200\.69\.211\.17/prod/global')||
		    window.location.href.match('^https:\/\/backend\.fundacionflexer\.org/prod/global')) {
			return 'FLEXER_PROD_GLOBAL';
		}*/
		/*if (window.location.href.match('^https:\/\/200\.69\.211\.17')) {
			return 'FLEXER';
		}
		if (window.location.href.match('^http:\/\/pruebagoogle\.onkelos\.com\.ar')) {
			return 'ONKELOS';
		}
		if (window.location.href.match('^http:\/\/fundacion\.indra\.devi\.onkelos\.com\.ar')) {
			return 'ONKELOS';
		} */
	}


}

/*import { Injectable, NgZone } from '@angular/core';

@Injectable()
export class AppSettingsService {

    //public API_ENDPOINT = '/api/index.php';
    //public API_ENDPOINT_V1 = '/api/v1';
    public API_ENDPOINT_V1:string;
    public AMBIENTE:string;
    public MODULE:string='gestion';
    public API_GET_IMAGE = '/api/get_image.php';
    public API_UPLOAD_PICTURE = '/api/upload_picture.php';
    public USE_IN_MEMORY_WEB_API = false;
   
    constructor() {
		let installedEnv = this.getInstalledEnv();
		if (installedEnv === 'UNIVERSUS') {
		   //this.API_ENDPOINT = 'http://universusnet.com.ar/sites/fid/api/index.php';
		   this.API_ENDPOINT_V1 = 'http://universusnet.com.ar/sites/fid/api/v1';
		   this.API_GET_IMAGE = 'http://universusnet.com.ar/sites/fid/api/get_image.php';
		   this.API_UPLOAD_PICTURE = 'http://universusnet.com.ar/sites/fid/api/upload_picture.php';

		} else if (installedEnv === 'FLEXER_DESA_GESTION') {
			//this.API_ENDPOINT = '/api/index.php';
			this.API_ENDPOINT_V1 = '/api/desa/gestion/';
			this.API_GET_IMAGE = '/api/get_image.php';
			this.API_UPLOAD_PICTURE = '/api/upload_picture.php';
			this.AMBIENTE='desa';
 
		}  
		else if (installedEnv === 'FLEXER_TEST_GESTION') {
			//this.API_ENDPOINT = '/api/index.php';
			this.API_ENDPOINT_V1 = '/api/test/gestion/';
			this.API_GET_IMAGE = '/api/get_image.php';
			this.API_UPLOAD_PICTURE = '/api/upload_picture.php';
			this.AMBIENTE='test';
 
		}  
		else if (installedEnv === 'FLEXER_PROD_GESTION') {
			//this.API_ENDPOINT = '/api/index.php';
			this.API_ENDPOINT_V1 = '/api/prod/gestion/';
			this.API_GET_IMAGE = '/api/get_image.php';
			this.API_UPLOAD_PICTURE = '/api/upload_picture.php';
			this.AMBIENTE='prod';

		}  
		else if (installedEnv === 'ONKELOS') {
		   //this.API_ENDPOINT = '/api/index.php';
		   this.API_ENDPOINT_V1 = '/api/v1';
		   this.API_GET_IMAGE = '/api/get_image.php';
		   this.API_UPLOAD_PICTURE = '/api/upload_picture.php';

		} else if (installedEnv === 'LOCAL8000') {
		   //this.API_ENDPOINT = 'http://localhost:8086/api/index.php';
		   this.API_ENDPOINT_V1 = 'http://localhost:8086/api/v1';
		   this.API_GET_IMAGE = 'http://localhost:8086/api/get_image.php';
		   this.API_UPLOAD_PICTURE = 'http://localhost:8086/api/upload_picture.php';

		} else if (installedEnv === 'LOCAL4200'||installedEnv === 'LOCAL100') {
		   //this.API_ENDPOINT = 'http://localhost:8086/api/index.php';
		   this.API_ENDPOINT_V1 = 'http://localhost:100/api/desa/gestion/';
		   this.AMBIENTE='local';

		}
    }
	
	public getInstalledEnv() {

		if (window.location.href.match('^http:\/\/localhost:8000')) {
			return 'LOCAL8000';
		}
		if (window.location.href.match('^http:\/\/localhost:4200')) {
			return 'LOCAL4200';
		}
		if (window.location.href.match('^http:\/\/localhost:100')) {
			return 'LOCAL100';
		}
		if (window.location.href.match('^http:\/\/universusnet\.com\.ar')) {
			return 'UNIVERSUS';
		}
		if (window.location.href.match('^https:\/\/backend\.fundacionflexer\.org/desa/gestion')||
		    window.location.href.match('^https:\/\/200\.69\.211\.17/desa/gestion')||
		    window.location.href.match('^http:\/\/200\.69\.211\.17/desa/gestion')) {
			return 'FLEXER_DESA_GESTION';
		}
		if (window.location.href.match('^https:\/\/backend\.fundacionflexer\.org/test/gestion')||
		    window.location.href.match('^https:\/\/200\.69\.211\.17/test/gestion')||
		    window.location.href.match('^http:\/\/200\.69\.211\.17/test/gestion')) {
			return 'FLEXER_TEST_GESTION';
		}
		if (window.location.href.match('^https:\/\/200\.69\.211\.17/gestion')||
		    window.location.href.match('^http:\/\/200\.69\.211\.17/gestion')) {
			return 'FLEXER_PROD_GESTION';
		}
		if (window.location.href.match('^http:\/\/fundacion\.indra\.devi\.onkelos\.com\.ar')) {
			return 'ONKELOS';
		} else {
			return 'FLEXER_DESA_GESTION';
//			return 'LOCAL';
		}
	}


}
*/