import { Component, Input, OnInit, NgZone } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { BackendService } from '../../../@core/backend/backend.service';
import { AnalyticsService } from '../../../@core/utils';
import { LayoutService } from '../../../@core/utils';
import { LoginService } from '../..//..//@core/login/login.service';
//import { ConsoleReporter } from 'jasmine';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  private loggedIn: boolean = false;

  //userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  userMenu = [{ title: 'Mi perfil' }, { title: 'Desconectarse' }];


  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private backendService: BackendService,
              private loginService: LoginService,
              //private userService: UserData,
              private ngZone:NgZone,
              private analyticsService: AnalyticsService,
              private layoutService: LayoutService) {
  }
/*
  ngOnInit() {
    console.log("headre ngOnInit");
    console.log(this.user);
    this.user =this.loginService.getUser();
    console.log(this.user);
    //this.backendService.get('/user/session/')
    //7.subscribe((user: any) => this.user = user);
   //
    //this.backendService.get('user_session')
    //.subscribe((user: any) => this.user = user);
    //this.userService.getUsers()
    //  .subscribe((users: any) => this.user = users.nick);
  }*/
  ngOnInit() {
    this.loginService.checkLogin(this);
    //this.userService.getUsers()
    //  .subscribe((users: any) => this.user = users.nick);
    this.menuService.onItemClick()
   .subscribe(
      title => {
        console.log(title.item['title']);
        if (title.item['title']=='Desconectarse'){
          this.backendService.get('deleteuser/session')
          .subscribe( (data: any,
            
            ) => {
            location.href='../global';
            /*this.loginService.checkLogin(this);
            console.log(data);
            console.log('hola1');*/
          },
          err => {
            console.log('Something went wrong!');
            location.href='../global';
          }
          );	 
        }
        if (title.item['title']=='Mi perfil'){
          location.href='../global';
        }
    }
      );
       this.getData();
      
/*    .subscribe(
        title => window.alert(`${title} was clicked!`));
*/ }
getData(){
  this.user=this.loginService.getUserName();
  //this.backendService.get('user_session')
  //.subscribe((user: any) => this.user = user);
  
}


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
  ngAfterViewInit():void {
    console.log('ngAfterViewInit1');
    this.loginService.getUser().subscribe( data=>
      this.user=data);
    console.log(this.user);
    /*setTimeout(_=> this.ngZone.run(() => {
      console.log('ngAfterViewInit12');
			
    this.loginService.getLoginObservable().subscribe((loggedIn) => {
      console.log('ngAfterViewInit123');
			this.loggedIn = loggedIn;
		});
    }),10000);*/
} 
/*ngDoCheck() {

  if (  this.user===undefined ||  Object.keys(this.user).length==0)
 {
    this.ngAfterViewInit();
  }else {        console.log('docheck this.user') ;
          console.log(this.user) ;
  }  
  //setTimeout(_=> console.log("header Change detection encountered!"),10000);
  //if (lth//this.ngAfterViewInit();  
  //setTimeout( _=>this.ngAfterViewInit()); 
  //setTimeout(_=>this.ngAfterViewInit(), 10000);
}
ngOnChanges(){
  console.log("CHANGES");
}*/
}
