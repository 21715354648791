/*
import { Injectable, NgZone } from '@angular/core';
import { Headers,  Response } from '@angular/http';
import { HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { BackendService } from '../backend/backend.service';

import { AppSettingsService } from '../backend/app-settings.service';




import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/filter';
import { Profile } from '../../../../node_modules/@types/selenium-webdriver/firefox';

declare var gapi: any;
@Injectable()
export class LoginService {

	private userInfo;
    private loginObservable = new BehaviorSubject<boolean>(false);
	private loggedIn:boolean = false;
    public auth2: any;
    user: any;
  
    constructor(
        private ngZone: NgZone,
        private http: HttpClient,
		private backendService:BackendService,
		private router:Router,
		private appSettingsService: AppSettingsService) {
		
		this.backendService.get('user/session/')		
			.subscribe( (data: any) => {
				this.user = data;	
				console.log('--> loginService constructor1');
				console.log(this.user);
			});	 
		console.log('--> loginService constructor');
		//debugger;
		//this.googleInit();
		this.ngZone.run(() => {
		this.loginObservable.subscribe((loggedIn) => {
			console.log('--> loginService loginObservable', loggedIn);
		});
	   });
	}
	
	setUserInfo(userInfo) {
		console.log('--> loginService setUserInfo');
		this.userInfo = userInfo;
	}

	public getUserInfo() {
		console.log('--> loginService getUserInfo');
		return this.userInfo;
	}
	
	public getLoginStatus() {
		console.log("getLoginStatus ->"+this.loggedIn)
		return this.loggedIn;
	}
	public setLoginStatus(aux :boolean) {
		setTimeout(_=> this.ngZone.run(() => {
			if (this.loggedIn!=aux)
			{
				this.backendService.get('user/session/')		
				.subscribe( (data: any) => {
				this.user = data;	
				console.log('--> loginService constructor1');
				console.log(this.user);
			});	 

			}
	   this.loggedIn=aux;console.log('nzone111='+aux);
		this.loginObservable.next(this.loggedIn);
		}));
	}

	public gotoLogin(){
		this.setLoginStatus(false);
		this.router.navigate(['pages','forms','login']);
  
	}
	public gotoFromAccount(){
		this.setLoginStatus(false);
		this.router.navigate(['pages','forms','account']);
  
	}
	public gotoFromBranch(){
		this.setLoginStatus(false);
		this.router.navigate(['pages','forms','branch']);
  
	}
	private checkBranch(){
		this.backendService.get('branch/session/')		
		.subscribe( (data: any) => {
		  if (data=='') this.gotoFromBranch();
		  else
		  {
			this.checkUser();
		  }  
		  }, err =>{
		this.gotoFromBranch();
	   console.error('Oops:', err.message);
		},() => {
			console.log(`We're done here!`);
		  });
			  
	}
	getUser(): Observable<any>{
			//if (this.loggedIn!=aux)
			//{
				return		this.backendService.get('user/session/')		;
				//.subscribe( (data: any) => {
				//this.user = data;	
				//console.log('--> loginService constructor1');
				//console.log(this.user);
			//});	 

			//}//

		//return this.user;
	}

	private checkUser(){
	
		this.backendService.get('user/session/')		
		.subscribe( (data: any) => {
			this.user = data;	
		  if (data['id']>0){
			this.setLoginStatus(true);
		  }
		  else this.gotoLogin();
		  }
		, err =>{
		  this.gotoLogin();
		 console.error('Oops:', err.message);
		},() => {
		  console.log(`We're done here!`);
		});
			
	}
	
	public checkLogin(){
		this.backendService.get('state/session/')		
		.subscribe( (data: any) => {
		  if (data=='')    { 
			  
			location.reload();
		  }
		  else
		  {
		    this.checkAccount();
			
		 }}, err =>{
		  this.gotoFromAccount();
		 console.error('Oops:', err.message);
		},() => {
		  console.log(`We're done here checkLogin!`);
		});
		


	}
	public checkAccount(){
		this.backendService.get('account/session/')		
		.subscribe( (data: any) => {
		  if (data=='')     this.gotoFromAccount();
		  else
		  {
		    this.checkBranch();
			
		 }}, err =>{
		  this.gotoFromAccount();
		 console.error('Oops:', err.message);
		},() => {
		  console.log(`We're done here!`);
		});
		


	}
	ngDoCheck() {
		console.log("Login Services Change detection encountered!")
		}
	public getLoginObservable():Observable<any> {
		 return this.backendService.get('session/user/');
		
    }
	public getLoginPromise() {
		let filteredLoginObservable = this.loginObservable
			.filter(loggedIn => loggedIn);
		
		let localResolve, localReject;
		let loginPromise = new Promise((resolve, reject) => {
			localResolve = resolve;
			localReject = reject;
		});
		filteredLoginObservable.subscribe(() => {
			localResolve();
		})
        console.log('loginPromise');
        console.log(loginPromise);
        console.log(this.getLoginStatus());
		return loginPromise
	}
	
	public getLoginPromiseBis() {
		let filteredLoginObservable = this.loginObservable
			.filter(loggedIn => loggedIn);
		
		let localResolve, localReject;
		let loginPromise = new Promise((resolve, reject) => {
			localResolve = resolve;
			localReject = reject;
		});
		filteredLoginObservable.subscribe(() => {
			localResolve();
		})
        console.log('loginPromise');
        console.log(loginPromise);
        console.log(this.getLoginStatus());
		return loginPromise
	}
	
	
    private googleInit() {
		//if (this.appSettingsService.getInstalledEnv() !== 'LOCAL' && this.appSettingsService.getInstalledEnv() !== 'LOCAL4200') {
		if (this.appSettingsService.getInstalledEnv() !== 'LOCAL' && this.appSettingsService.getInstalledEnv() == 'LOCAL4200') {
//		if (this.appSettingsService.getInstalledEnv() !== 'LOCAL4200') {
			gapi.load('auth2', () => {
				// https://developers.google.com/identity/sign-in/web/listeners
				this.auth2 = gapi.auth2.init({
				    //client_id: '772413651665-jaa79iq8l2fqvnufkvkrjbm33v70njfv.apps.googleusercontent.com',
					client_id: '809379640545-8f0s39k2vaa55vjpa81713otoi0vr5da.apps.googleusercontent.com'
                  //cliente_id:'976731384175-4b8juls1b0rcctooqf1mqsupcsn459vl.apps.googleusercontent.com',/*,
                   // scope: 'profile'
				});
				
				//https://stackoverflow.com/questions/33378710/google-sign-in-api-issignedin-get-returning-inconsistent-values
				this.auth2.then((d)  => {
					console.log('d.isSignedIn.get()=', d.isSignedIn.get());
					setTimeout(_=> this.ngZone.run(() => {
                        console.log('loguerse=');
                        this.loggedIn = d.isSignedIn.get();
					    console.log(this.loggedIn);
                
                        if (this.loggedIn) {
						   this.getUserInformationFromGoogle();
					   } else {
						   this.eraseUserInformation();
					   }
					   this.loginObservable.next(this.loggedIn);
					}));
				});
			});
        } 
    }
	
    private getUserInformationFromGoogle() {
        let profile = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile() 
        //console.log('Token || ' + googleUser.getAuthResponse().id_token);
        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail());
		this.setUserInfo({
			id: profile.getId(),
			name: profile.getName(),
			imageUrl: profile.getImageUrl(),
			email: profile.getEmail(),
			token: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token
		});
    }

    private eraseUserInformation() {
        delete this.userInfo;
    }
	public login_server()
	{
			let headers1 = new Headers();
			const token='YWxhZGRpbjpvcGVuc2VzYW1l';
			const headers = new HttpHeaders().set('Authorization', `Basic ${token}`);
		
			let userInfo = this.getUserInfo();
			if (userInfo) {
				headers.append('email', userInfo.email);
				headers.append('token', userInfo.token);
				headers.append('Content-Type', 'application/json');
				//let killCache = this.killCache();
				let url = `${this.appSettingsService.API_ENDPOINT_V1}/user/session179`;
                return 
                this.http.get(url, { headers: headers })
			  	  .toPromise()
				  .catch((error: any) => {
					  console.error('An friendly error occurred', error);
					  return error;
				  });
			}

	}
    public logout_server()
	{
			let headers = new Headers();
			let userInfo = this.getUserInfo();
			if (userInfo) {
				headers.append('email', userInfo.email);
				headers.append('token', userInfo.token);
				headers.append('Content-Type', 'application/json');
				//let killCache = this.killCache();
				let url = `${this.appSettingsService.API_ENDPOINT_V1}/user/session199`;
				return this.http
				.put(url, { headers: headers })
			  	  .toPromise()
				  .catch((error: any) => {
					  console.error('An friendly error occurred', error);
					  return error;
				  });
			}

	}
    public signIn() {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signIn().then(() => {
			this.getUserInformationFromGoogle();
			this.login_server();
            setTimeout(_=> this.ngZone.run(() => {
				this.loggedIn = true;
				this.loginObservable.next(this.loggedIn);
				
            }));
        });
    }
	
    public signOut() {
        var that = this;
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
			this.eraseUserInformation();
			console.log('logout 1');
            this.logout_server();
            console.log('logout 2');
            setTimeout(_=> this.ngZone.run(() => {
				this.loggedIn = false;
				this.loginObservable.next(this.loggedIn);
            }));
        });
    }
}
*/
import { Injectable, NgZone } from '@angular/core';
import { Headers,  Response } from '@angular/http';
import { HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { BackendService } from '../backend/backend.service';
import { ConfigService } from '../config/config.service';

//import { AppSettingsService } from '../backend/app-settings.service';




import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/filter';
import { Profile } from '../../../../node_modules/@types/selenium-webdriver/firefox';
import { throws } from 'assert';

declare var gapi: any;
@Injectable()
export class LoginService {

	private userInfo;
    private loginObservable = new BehaviorSubject<boolean>(false);
	private loggedIn:boolean = false;
	public auth2: any;
	private componentsFrom=Array();
    private comentario:string='hola';
    user: any;
	//private ngZone: NgZone;
	//private backendService:BackendService,
	private configService:ConfigService;
	
    constructor(
		private backendService:BackendService,
		private ngZone: NgZone,
		private http: HttpClient,
		private router:Router,
	    //private appSettingsService: AppSettingsService
		) {
		console.log('constr Login');
		this.configService=new ConfigService(this.ngZone,this.http,this.backendService,this.router);
		console.log('configService');
		console.log(this.configService);
		this.backendService.get('user/session/')		
			.subscribe( (data: any) => {
				this.user = data;	
				this.userInfo = data;	
				console.log('--> loginService constructor1');
				console.log(this.user);
			});	 
		console.log('--> loginService constructor');
		//debugger;
		//this.googleInit();
		this.ngZone.run(() => {
		this.loginObservable.subscribe((loggedIn) => {
			console.log('--> loginService loginObservable', loggedIn);
		});
	   });
	}
	
	setUserInfo(userInfo) {
		console.log('--> loginService setUserInfo');
		this.userInfo = userInfo;
		console.log(this.userInfo);
	}
    private _goNext(stepBefore:string){
		console.log('goNext->'+stepBefore);
		var order:string,ifBranch:string;
		order=this.configService.get('order_autentication');
		if (order==='') order='account_and_user';
		ifBranch=this.configService.get('branch');
		console.log('ifBranch');console.log(ifBranch);
		console.log('order');console.log(order);
		console.log('stepBefore->'+stepBefore);
		if (order=='account_and_user'&&stepBefore=='')
		{
			console.log('order1');this.checkAccount(stepBefore);
		}
		else if (order=='account_and_user'&&stepBefore=='account'&&ifBranch=='Y')	
		{
			console.log('order2');this.checkBranch();
	    }
		else if (order=='account_and_user'&&stepBefore=='branch')	
		{
			console.log('order2');this.checkUser();
	    }
		else if (order=='account_and_user'&&stepBefore=='account'&&ifBranch=='N')	
		{
			//this.gotoFromAccount();
			console.log('order3');this.checkUser();
	    }
		else if (order=='account_and_user'&&stepBefore=='user')	
		{
			this.gotoHome();
	    }
		else if (order=='user_and_account'&&stepBefore=='')
	       this.checkUser();
	    else if (order=='user_and_account'&&stepBefore=='user')
			//this.gotoFromAccount();
			this.checkAccount(stepBefore);
		else if (order=='user_and_account'&&stepBefore=='account'&&ifBranch=='N')	
			this.gotoHome();
		else if (order=='user_and_account'&&stepBefore=='account'&&ifBranch=='Y')	
			//this.chegotoFromBranch();
			this.checkBranch()
		else if (stepBefore=='branch')	
			this.gotoHome();
		/*else if (order=='account_and_user'&&stepBefore=='user'&&ifBranch=='Y')	
			this.gotoHome();
		else {console.log('no esta correctamnete definido el parametro ´order_autentication´');	
		this.checkUser();
	    }*/

	}
	public goNext(stepBefore:string){
		if (stepBefore=='account')
		{
		//account cambia la configuracion	
		this.configService.refresh().subscribe( (data: any) => {
			this.configService.setData( data);	
			this._goNext(stepBefore);
		  });
		}
		else this._goNext(stepBefore);

	}
	public getUserInfo() {
		console.log('--> loginService getUserInfo');
		return this.userInfo;
	}
	public getUserName() {
		try{
		console.log('--> loginService getUserInfo');
		console.log(this.userInfo);
		return {'name:':this.userInfo.firstName};
	    }catch(e){}
	}
	public getHaveAnyPermissions(permissions:Array<string>){
		for(var index in permissions)
		{ 
			if (this.getPermission(permissions[index]))
			   return true;  // output: Apple Orange Banana
		}		
		return false;
	}
	public getPermission(name:string){
		try{
		console.log('--> getPermission');
		//console.log(this.userInfo);
		//console.log(this.userInfo.client_name);
		
		var permAccount:any;
		//permAccount=this.userInfo.permission[this.userInfo.client_name];
		//console.log(this.userInfo.permissions);
		permAccount=this.userInfo.permissions['SUCATHDAVID'];
		//console.log(permAccount);
		//console.log(name);
		//console.log(permAccount[name]);

		//console.log('--> getPermission return'+(permAccount[name]?true:false));
		
		return permAccount[name]?true:false;
		}
		catch(e){
			console.log('--> getPermission FALSE');
		
			return false;
		}
	}
	
	
	public getLoginStatus() {
		console.log("getLoginStatus ->"+this.loggedIn)
		return this.loggedIn;
	}
	public setLoginStatus(aux :boolean) {
		setTimeout(_=> this.ngZone.run(() => {
			if (this.loggedIn!=aux)
			{
				this.backendService.get('user/session/')		
				.subscribe( (data: any) => {
				this.user = data;	
				console.log('--> loginService constructor1');
				console.log(this.user);
				console.log(aux);
			});	 

			}
	   this.loggedIn=aux;console.log('nzone111='+aux);
		this.loginObservable.next(this.loggedIn);
		}));
	}

	public gotoHome(){
		this.setLoginStatus(false);
		//this.router.navigate(['pages','tables','aportantes-table']);
		//this.router.navigate(['pages/tables/home']);
		//this.router.navigate([this.userInfo.url_initial]);
		location.href='/'+this.backendService.getAmbiente()+this.userInfo.url_initial;
		
	}
	public gotoLogin(){
		this.setLoginStatus(false);
		this.router.navigate(['pages','forms','login']);
  
	}
	public gotoFromAccount(){
		//viene aca 
		this.setLoginStatus(false);
		this.router.navigate(['pages','forms','account']);
  
	}
	public gotoFromBranch(){
		this.setLoginStatus(false);
		this.router.navigate(['pages','forms','branch']);
  
	}
	public checkBranch(){
	  console.log('checkBranch()');
	  console.log(this.configService.get('branch'));
	  if (this.configService.get('branch')=='Y')
	  //if (true)
	  {
		this.backendService.get('branch/session/')		
		.subscribe( (data: any) => {
		  if (data=='') this.gotoFromBranch();
		  else
		  {
			//this.checkUser();
			this.goNext('branch');
		  }  
		  }, err =>{
		this.gotoFromBranch();
	   console.error('Oops:', err.message);
		},() => {
			console.log(`We're done here!`);
		  });
	 }
	 else {
		this.checkUser();
		 
	 }  
			  
	}
	getUser(): Observable<any>{
			//if (this.loggedIn!=aux)
			//{
				return		this.backendService.get('user/session/')		;
				/*.subscribe( (data: any) => {
				this.user = data;	
				console.log('--> loginService constructor1');
				console.log(this.user);
			});	 */

			//}//

		//return this.user;
	}

	private checkUser(){
		console.log('checkUser()');
	 	this.backendService.get('user/session/')		
		.subscribe( (data: any) => {
			this.user = data;	
			console.log('data()');
			console.log(data);
			var aux:number=0;
			try{
				aux=data['id'];
			}
			catch(error){
				aux=0;
			};	
			if ( aux>0){	
				console.log('checkUser2()');
				this.setLoginStatus(true);
				console.log('checkUser2()');
				this.refreshComponents();
				console.log('checkUser3()');
  		    }
		    else this.gotoLogin();
		  }
		, err =>{
		  this.gotoLogin();
		 console.error('Oops:', err.message);
		},() => {
		  console.log(`We're done here!`);
		});
			
	}
	
	public checkLoginAnterior(){
		this.comentario=this.comentario+" checkLogin";
		console.log('this.comentario');
		console.log(this.comentario);
		this.backendService.get('state/session/')		
		.subscribe( (data: any) => {
		  if (data=='')    { 
			  
			//location.reload();
		  }
		  else
		  {
		    this.checkAccount(null);
			
		 }}, err =>{
		  this.gotoFromAccount();
		 console.error('Oops:', err.message);
		},() => {
		  console.log(`We're done here checkLogin!`);
		});
		


	}
	private refreshComponents(){
		for(var index in this.componentsFrom)
		{ 
			this.componentsFrom[index].getData();  // output: Apple Orange Banana
		}
		this.componentsFrom=Array();	
	}
	public checkLogin(pComponent:any){
		this.componentsFrom.push(pComponent);
		console.log('checkLogin');
		this.backendService.get('state/session/')		
		.subscribe( (data: any) => {
			if (data=='')    {
				this.goNext('');
	
				if (this.configService.get('order_autentication')=='user_and_account')
                	this.gotoLogin();
				else if (this.configService.get('order_autentication')=='account_and_user')
					this.gotoFromAccount();
				else {
					console.log('no esta correctamnete definido el parametro ´order_autentication´');
					this.gotoFromAccount(); // tomo esto porque es Flexer
				}	
			}
			else
		  {
			console.log('order_autentication->'+this.configService.get('order_autentication'));
			if (this.configService.get('order_autentication')=='user_and_account')
    			this.checkUser();
			else if (this.configService.get('order_autentication')=='account_and_user')
				this.checkAccount(null);
			else {
					console.log('no esta correctamnete definido el parametro ´order_autentication´');
					this.checkAccount(null);
				}		
	
		 }}, err =>{
			if (this.configService.get('order_autentication')=='user_and_account')
				this.gotoLogin();
			else if (this.configService.get('order_autentication')=='user_and_account')
				this.gotoFromAccount();
			else {
				console.log('no esta correctamnete definido el parametro ´order_autentication´');	
        		console.error('Oops:', err.message);
				this.gotoFromAccount();
			}
		},() => {
		  console.log(`We're done here checkLogin!`);
		});
		


	}
	
	public checkAccount(step:string){
		console.log('checkAccount('+step+')');
		//if (step==='') this.gotoFromAccount();
		this.backendService.get('account/session/')		
		.subscribe( (data: any) => {
		  if (data=='')     this.gotoFromAccount();
		  else
		  {
			this.goNext('account');
			
		 }}, err =>{
		  this.gotoFromAccount();
		 console.error('Oops:', err.message);
		},() => {
		  console.log(`We're done here!`);
		});
		


	}
	ngDoCheck() {
		console.log("Login Services Change detection encountered!")
		}
	public getLoginObservable():Observable<any> {
		 return this.backendService.get('session/user/');
		
		/*if (this.getLoginStatus()) return new BehaviorSubject<any>(true);
		else
		{ console.log('hola4');
		this.backendService.get('session/user/').subscribe((data: any) => {
			this.setLoginStatus(true);
			return new BehaviorSubject<any>(data);
		  });
		}*/
    }
	public getLoginPromise() {
		let filteredLoginObservable = this.loginObservable
			.filter(loggedIn => loggedIn);
		
		let localResolve, localReject;
		let loginPromise = new Promise((resolve, reject) => {
			localResolve = resolve;
			localReject = reject;
		});
		filteredLoginObservable.subscribe(() => {
			localResolve();
		})
        console.log('loginPromise');
        console.log(loginPromise);
        console.log(this.getLoginStatus());
		return loginPromise
	}
	
	public getLoginPromiseBis() {
		let filteredLoginObservable = this.loginObservable
			.filter(loggedIn => loggedIn);
		
		let localResolve, localReject;
		let loginPromise = new Promise((resolve, reject) => {
			localResolve = resolve;
			localReject = reject;
		});
		filteredLoginObservable.subscribe(() => {
			localResolve();
		})
        console.log('loginPromise');
        console.log(loginPromise);
        console.log(this.getLoginStatus());
		return loginPromise
	}
	
	
    private googleInit() {
		//if (this.appSettingsService.getInstalledEnv() !== 'LOCAL' && this.appSettingsService.getInstalledEnv() !== 'LOCAL4200') {
		//if (this.appSettingsService.getInstalledEnv() !== 'LOCAL' && this.appSettingsService.getInstalledEnv() == 'LOCAL4200') {
//		if (this.appSettingsService.getInstalledEnv() !== 'LOCAL4200') {
	    if (false){
			gapi.load('auth2', () => {
				// https://developers.google.com/identity/sign-in/web/listeners
				this.auth2 = gapi.auth2.init({
				    //client_id: '772413651665-jaa79iq8l2fqvnufkvkrjbm33v70njfv.apps.googleusercontent.com',
					client_id: '809379640545-8f0s39k2vaa55vjpa81713otoi0vr5da.apps.googleusercontent.com'
                  //cliente_id:'976731384175-4b8juls1b0rcctooqf1mqsupcsn459vl.apps.googleusercontent.com',/*,
                   /* scope: 'profile'*/
				});
				
				//https://stackoverflow.com/questions/33378710/google-sign-in-api-issignedin-get-returning-inconsistent-values
				this.auth2.then((d)  => {
					console.log('d.isSignedIn.get()=', d.isSignedIn.get());
					setTimeout(_=> this.ngZone.run(() => {
                        console.log('loguerse=');
                        this.loggedIn = d.isSignedIn.get();
					    console.log(this.loggedIn);
                
                        if (this.loggedIn) {
						   this.getUserInformationFromGoogle();
					   } else {
						   this.eraseUserInformation();
					   }
					   this.loginObservable.next(this.loggedIn);
					}));
				});
			});
        } 
        /*else {
			// Mock login
			setTimeout(_=> this.ngZone.run(() => {
				this.loggedIn = true;
				// Mock login info
				this.setUserInfo({
					id: 'mockId',
					name: 'mockName',
					imageUrl: '',
					email: 'aribecker2@gmail.com',
					token: 'mockToken'
				});
				this.loginObservable.next(this.loggedIn);
			}));
		}*/
    }
	
    private getUserInformationFromGoogle() {
        let profile = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile() 
        //console.log('Token || ' + googleUser.getAuthResponse().id_token);
        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail());
		this.setUserInfo({
			id: profile.getId(),
			name: profile.getName(),
			imageUrl: profile.getImageUrl(),
			email: profile.getEmail(),
			token: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token
		});
    }

    private eraseUserInformation() {
        delete this.userInfo;
    }
	public login_server()
	{
			let headers1 = new Headers();
			const token='YWxhZGRpbjpvcGVuc2VzYW1l';
			const headers = new HttpHeaders().set('Authorization', `Basic ${token}`);
		
			let userInfo = this.getUserInfo();
			if (userInfo) {
				headers.append('email', userInfo.email);
				headers.append('token', userInfo.token);
				headers.append('Content-Type', 'application/json');
				//let killCache = this.killCache();
				//let url = `${this.appSettingsService.API_ENDPOINT_V1}/user/session179`;
                let url = `/user/session179`;
                return 
                this.http.get(url, { headers: headers })
			  	  .toPromise()
				  .catch((error: any) => {
					  console.error('An friendly error occurred', error);
					  return error;
				  });
			}

	}
    public logout_server()
	{
			let headers = new Headers();
			let userInfo = this.getUserInfo();
			if (userInfo) {
				headers.append('email', userInfo.email);
				headers.append('token', userInfo.token);
				headers.append('Content-Type', 'application/json');
				//let killCache = this.killCache();
				//let url = `${this.appSettingsService.API_ENDPOINT_V1}/user/session199`;
				let url='ddd';
				return this.http
				.put(url, { headers: headers })
			  	  .toPromise()
				  .catch((error: any) => {
					  console.error('An friendly error occurred', error);
					  return error;
				  });
			}

	}
    public signIn() {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signIn().then(() => {
			this.getUserInformationFromGoogle();
			this.login_server();
            setTimeout(_=> this.ngZone.run(() => {
				this.loggedIn = true;
				this.loginObservable.next(this.loggedIn);
				
            }));
        });
    }
	
    public signOut() {
        var that = this;
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
			this.eraseUserInformation();
			console.log('logout 1');
            this.logout_server();
            console.log('logout 2');
            setTimeout(_=> this.ngZone.run(() => {
				this.loggedIn = false;
				this.loginObservable.next(this.loggedIn);
            }));
        });
    }
}
